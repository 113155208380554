import React, { useState } from 'react';
import Page from '../components/Page';


function Testpage({ collapsed, toggleSidebar, showOffcanvas, handleShowOffcanvas, handleCloseOffcanvas }) {
    
    return (
      <Page 
        collapsed={collapsed} 
        toggleSidebar={toggleSidebar} 
        showOffcanvas={showOffcanvas}
        handleShowOffcanvas={handleShowOffcanvas}
        handleCloseOffcanvas={handleCloseOffcanvas}
      >
        <h3>HALOW</h3>
        
      </Page>
  
    );
  }

export default Testpage;
